//import Module
import React from "react";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

import albumArray from "../data/album.json"

//import Style
import '../styles/home.css';

function AlbumArticle(props){
    return(
        <div className="NewestAlbum" id="newAlbums">
            <div>
                <img src={props.imgSrc} oncontextmenu="return false;"/>
            </div>
            <div>
                <span>{props.albumTitle}</span>
                <span>{props.albumLocales}</span>
            </div>
        </div>
    )
    
}

function Discography(){
    const { t } = useTranslation();

    const albumList = albumArray.albums.map((albums, index) => {
        return(
            <Link to={`/lyrics/${albums.url}`}>
                <AlbumArticle 
                imgSrc={albums.img}
                albumTitle={albums.title}
                albumLocales={t(`${albums.url}`)}
                albumArtists={albums.artist}
                />
            </Link>
        )
    })
    
    return(
        <div>
            {albumList}
        </div>
    )
}

export default Discography;